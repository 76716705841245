<template>
  <div
    id="kt_header_mobile"
    class="header-mobile align-items-center header-mobile-fixed"
  >
    <!--begin::Aside Mobile Toggle-->
    <button
      class="btn p-0 burger-icon"
      id="kt_aside_mobile_toggle"
    >
      <span></span>
    </button>
    <!--end::Aside Mobile Toggle-->

    <!--begin::Logo-->
    <a href="/">
      <img alt="Logo" :src="assets.logoLight" :height="20" />
    </a>
    <!--end::Logo-->

    <!--begin::Toolbar-->
    <div class="d-flex">
      <KTLanguageBar></KTLanguageBar>
      <KTUserbar></KTUserbar>
    </div>
    <!--end::Toolbar-->
  </div>
</template>

<script>
import KTLayoutHeaderTopbar from "@/assets/js/layout/base/header-topbar.js";
import KTUserbar from "@/view/layout/header/Userbar.vue";
import KTLanguageBar from "@/view/layout/header/LanguageBar.vue";

import logoLight from '@/assets/image/logo_light.png';

export default {
  name: "KTHeaderMobile",
  components: {
    KTUserbar,
    KTLanguageBar
  },
  data() {
    return {
      assets: {
        logoLight
      }
    }
  },
  mounted() {
    // Init Header Topbar For Mobile Mode
    KTLayoutHeaderTopbar.init(this.$refs["kt_header_mobile_topbar_toggle"]);
  }
};
</script>
